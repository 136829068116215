<template>
  <div id="app"
    class="privacy-policy page-template-default page page-id-3 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="https://gbapks.com.ru/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="https://gbapks.com.ru/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>

        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="https://gbapks.com.ru/" aria-current="page">Home</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://gbapks.com.ru/gbwhatsapp-downloadpage/">Download</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="https://gbapks.com.ru/blogs/">Blogs</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <article id="post-3" class="post-3 page type-page status-publish">
              <div class="inside-article">
                <header class="entry-header" aria-label="Content">
                  <h1 class="entry-title" itemprop="headline">
                    Privacy Policy
                  </h1>
                </header>

                <div class="entry-content" itemprop="text">
                  <h2>Who we are</h2>
                  <p>
                    Our website
                    address is: https://gbapks.com.ru.
                  </p>
                  <h2>Comments</h2>
                  <p>
                    When visitors
                    leave comments on the site we collect the data
                    shown in the comments form, and also the visitor&#8217;s IP address and browser
                    user agent string to help spam detection.
                  </p>
                  <p>
                    An anonymized string created from your email address (also called a hash) may be
                    provided to the Gravatar service to see if you
                    are using it. The Gravatar service privacy policy is available here:
                    https://automattic.com/privacy/. After approval of your
                    comment, your profile picture is visible to the public in the context of your
                    comment.
                  </p>
                  <h2>Media</h2>
                  <p>
                    If you upload
                    images to the website, you should avoid uploading
                    images with embedded location data (EXIF GPS) included. Visitors to the website
                    can download and extract any location data from
                    images on the website.
                  </p>
                  <h2>Cookies</h2>
                  <p>
                    If you leave a
                    comment on our site you may opt-in to saving your
                    name, email address and website in cookies. These are for your convenience so
                    that you do not have to fill in your details again
                    when you leave another comment. These cookies will last for one year.
                  </p>
                  <p>
                    If you visit our login page, we will set a temporary cookie to determine if your
                    browser accepts cookies. This cookie contains no
                    personal data and is discarded when you close your browser.
                  </p>
                  <p>
                    When you log in, we will also set up several cookies to save your login
                    information and your screen display choices. Login cookies
                    last for two days, and screen options cookies last for a year. If you select
                    &quot;Remember Me&quot;, your login will persist for
                    two weeks. If you log out of your account, the login cookies will be removed.
                  </p>
                  <p>
                    If you edit or publish an article, an additional cookie will be saved in your
                    browser. This cookie includes no personal data and
                    simply indicates the post ID of the article you just edited. It expires after 1
                    day.
                  </p>
                  <h2>Embedded content from other websites</h2>
                  <p>
                    Articles on
                    this site may include embedded content (e.g. videos,
                    images, articles, etc.). Embedded content from other websites behaves in the
                    exact same way as if the visitor has visited the
                    other website.
                  </p>
                  <p>
                    These websites may collect data about you, use cookies, embed additional
                    third-party tracking, and monitor your interaction with
                    that embedded content, including tracking your interaction with the embedded
                    content if you have an account and are logged in to
                    that website.
                  </p>
                  <h2>Who we share your data with</h2>
                  <p>
                    If you request
                    a password reset, your IP address will be included
                    in the reset email.
                  </p>
                  <h2>How long we retain your data</h2>
                  <p>
                    If you leave a
                    comment, the comment and its metadata are retained
                    indefinitely. This is so we can recognize and approve any follow-up comments
                    automatically instead of holding them in a moderation
                    queue.
                  </p>
                  <p>
                    For users that register on our website (if any), we also store the personal
                    information they provide in their user profile. All
                    users can see, edit, or delete their personal information at any time (except
                    they cannot change their username). Website
                    administrators can also see and edit that information.
                  </p>
                  <h2>What rights you have over your data</h2>
                  <p>
                    If you have an
                    account on this site, or have left comments, you
                    can request to receive an exported file of the personal data we hold about you,
                    including any data you have provided to us. You
                    can also request that we erase any personal data we hold about you. This does
                    not include any data we are obliged to keep for
                    administrative, legal, or security purposes.
                  </p>
                  <h2>Where we send your data</h2>
                  <p>
                    Visitor
                    comments may be checked through an automated spam
                    detection service.
                  </p>
                </div>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="https://gbapks.com.ru/privacy/">Privacy Policy</a> |
            <a href="https://gbapks.com.ru/about-us/">About Us</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="https://gbapks.com.ru/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';

export default {
  name: 'Privacy',
  data () {
    return {
      pageName: 'privacy',
    };
  },
  mounted () { },
};
</script>
